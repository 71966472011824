import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./livestockVetCareSolutions.css";
import VetCare1 from "../../images/vetcare1.png";
import VetCare2 from "../../images/vetcare2.png";
import VetCare3 from "../../images/vetcare3.png";

const livestockVetCareSolutions = () => {
  const images = [VetCare1, VetCare2, VetCare3];

  return (
    <div>
      <div className="vetCareLayout">
        <div className="vetCareInfo">
          <h1 className="vetCareHeading" data-aos="fade-right" data-aos-duration="500">Our Vetcare Solutions</h1>
          <p className="vetCarePText" data-aos="fade-right" data-aos-duration="700">
            CIC’s veterinary care solutions arm provides a range of
            internationally acclaimed veterinary care products to support the
            needs of the Country’s poultry industry and pet care market.
          </p>
          <div className="vetCarePText" data-aos="fade-right" data-aos-duration="700">
            Our cluster is a key player in the veterinary drugs/products and
            animal feeds additive market in Sri Lanka. Our principals include
            the world’s top producers of poultry and other veterinary vaccines
            as well as related products that cater to the needs of pet owners.
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#EFEFEF", paddingBottom: "100px" }}>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          spaceBetween={30}
          slidesPerView={1}
        >
          {images.map((src, index) => (
            <SwiperSlide key={index}>
              <img src={src} alt={`Slide ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default livestockVetCareSolutions;