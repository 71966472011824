import React from "react"
import LivestockSolutionsMainBanner from "../templates/LivestockSolutions/livestockSolutionsMainBanner"
import LivestockSolutionsOverview from "../templates/LivestockSolutions/livestockSolutionsOverview"
import LivestockSolutionsOurStateOfArt from "../templates/LivestockSolutions/livestockSolutionsOurStateOfArt"
import LivestockVetCareSolutions from "../templates/LivestockSolutions/livestockVetCareSolutions"

export default function LivestockSolutions() {
    return (
        <div>
            <LivestockSolutionsMainBanner />
            <LivestockSolutionsOverview />
            <LivestockSolutionsOurStateOfArt />
            <LivestockVetCareSolutions />
        </div>
    )
}