import React, { useEffect } from 'react'
import "./livestockSolutionsOurStateOfArt.css"
import Aos from "aos";
import "aos/dist/aos.css";
import PlaceholderImage1 from "../../images/ourstateofart1.png"
import CropSegVector from "../../images/vector-61.svg"

export default function LivestockSolutionsOurStateOfArt() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    return (
        <div>
            <div className="StockStateOfArt-layout-4">
                <div className="StockStateOfArt-container3">
                    <div className="StockStateOfArt-content5">
                        <div className="StockStateOfArt-content6">
                            <div className="StockStateOfArt-section-title">
                                <div className="StockStateOfArt-content4">
                                    <div className="StockStateOfArt-heading" data-aos="fade-right" data-aos-duration="500">
                                        Besto
                                    </div>
                                    <div className="StockStateOfArt-text1" data-aos="fade-right" data-aos-duration="500">
                                        Our state-of-the-art Hatchery in Pannala produces high-quality Day Old Chicks (DOC), whilst our poultry breeding farms in Waradala, Pannala and Akaragama with a collective breeding capacity of nearly 200,000 birds at any given time, supply almost 10% of the country’s compound poultry market.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img
                        data-aos="fade-left" data-aos-duration="500"
                        className="StockStateOfArt-placeholder-image-icon"
                        alt=""
                        src={PlaceholderImage1}
                    />
                </div>
            </div>
        </div>
    )
}
