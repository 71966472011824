import React from "react";
import "./livestockSolutionsMainBanner.css";
import LivestockSolutionMain from "../../images/livestocksolutionsbanner.webp";
import SustainVideo from "../../images/aboutMainBanner_animation.mp4";

export default function AboutMainBanner() {
  return (
    <div>
      <div>
        <div style={{ position: "relative" }}>
          <img
            className="livestockMain-home-image"
            alt=""
            src={LivestockSolutionMain}
          />
          {/* <video
            className="aboutMain-home-image"
            src={SustainVideo}
            autoPlay
            loop
            muted
          /> */}
          {/* <div class="aboutMainBannerparallax"></div> */}
          <div className="livestockMain-image-text-box">
            <div className="livestockMain-image-background-text">
              <h1 className="livestockMain-image-topic">Livestock <br /> Solutions</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
